import { ANALYTICS_URL as prefix } from 'constants/analytic';

import { AssociatedCollections } from 'module/Collection/Collection.type';
import { Backfill, Session } from 'module/Session/Session.type';
import { TagGroup, convertTagsToTagNames } from 'module/Tag';

import {
  AnalyticCreate,
  AnalyticDeploymentDetail,
  AnalyticDeploymentPayload,
  AnalyticLanguage,
  AnalyticLogState,
  AnalyticReferences,
  CloneResponse,
  ConfigType,
  Language,
  LanguageExtended,
  LanguageTargetPayload,
  LogsourceSource,
  RelatedSession,
  SigmaBackend,
  SigmaConfig,
  SigmaEngine,
  WorkflowState
} from 'types/analytic';
import { Permitted } from 'types/auth';
import { Artifact, ArtifactScore, Guid, Ident, Visibility } from 'types/common';
import { Ops } from 'types/filter';
import { HalHitAnalytic } from 'types/halHits';
import { LogRecord } from 'types/ide';
import { BlueMarker, Page } from 'types/marker';

import { snapattack } from '../snapattack';

export type LogPayload = {
  session_id: number;
  analytic_tag: string;
  row_id: string;
  log_content: LogRecord;
};

export type TestResultsResponse = {
  analytic_tag: string;
  events: HalHitAnalytic[];
  sessions: Session[];
  testing_in_progress: boolean;
};

export function getFields(): Promise<LogsourceSource[]> {
  return snapattack.get(`${prefix}/logsource/`).then(r => r.data);
}

export function getAnalyticCollectionAssociations(guid: Guid): Promise<AssociatedCollections[]> {
  const url = `${prefix}/signature/${guid}/collections/`;
  return snapattack.get(url).then(r => r.data);
}

export function getAnalytic(guid: Guid): Promise<Artifact> {
  return snapattack.get(`${prefix}/signature/${guid}/`).then(r => r.data);
}

export function regenerateAnalyticDescription(guid: Guid): Promise<void> {
  return snapattack.get(`/cyberai/describing/${guid}/`).then(r => r.data);
}

export function getAnalyticBackfill(guid: Guid): Promise<Backfill> {
  return snapattack.get(`${prefix}/signature/${guid}/backfill_status/`).then(r => r.data);
}
export function getAnalyticReferences(guid: Guid): Promise<AnalyticReferences> {
  return snapattack.get(`${prefix}/signature/${guid}/references/`).then(r => r.data);
}

export function getAnalyticPermissions(guid: Guid): Promise<Permitted> {
  return snapattack.get(`${prefix}/signature/${guid}/permissions/`).then(r => ({
    permission: r.data
  }));
}

export function getTestingInProgress(guid: Guid): Promise<TestResultsResponse> {
  return snapattack.get(`${prefix}/${guid}/test/`).then(r => r.data);
}

/**
 * In most cases, you shouldn't use this directly. Instead, leverage provider > useLanguageContext()
 */
export function getLanguageTargets(): Promise<Language[]> {
  return snapattack.get(`${prefix}/language/`).then(r => r.data);
}

export function getLanguageTargetsExtended(): Promise<LanguageExtended[]> {
  return snapattack.get(`${prefix}/language/detailed/`).then(r => r.data);
}

export function postLanguageTarget(payload: LanguageTargetPayload): Promise<void> {
  return snapattack.post(`${prefix}/translate/backend/`, payload);
}

export function putLanguageTarget(targetId: Ident, payload: LanguageTargetPayload): Promise<void> {
  return snapattack.put(`${prefix}/translate/backend/${targetId}/`, payload);
}

export function postLanguageTargetRecompile(targetId: Ident): Promise<void> {
  return snapattack.post(`${prefix}/translate/backend/${targetId}/recompile/`);
}

export function deleteLanguageTarget(targetId: Ident): Promise<void> {
  return snapattack.delete(`${prefix}/translate/backend/${targetId}/`);
}

export function putHiddenLanguages(orgGuid: Guid, targets: Ident[]): Promise<void> {
  return snapattack.put(`/org/${orgGuid}/`, {
    mandatory_preference: { hidden_analytic_compilation_targets: targets }
  });
}

export async function getAnalyticLanguages(guid: Guid): Promise<AnalyticLanguage[]> {
  if (!guid) return (await getLanguageTargets()) as AnalyticLanguage[];
  return await snapattack.get(`${prefix}/signature/${guid}/language/`).then(r => r.data);
}

export function postAnalyticRank(guid: Guid, rank: ArtifactScore, organization_id: Ident): Promise<void> {
  return snapattack.post(`${prefix}/signature/${guid}/rank/`, { rank, organization_id }).then(() => {});
}

export function postSeverityRank(guid: Guid, severity: ArtifactScore, organization_id: Ident): Promise<void> {
  return snapattack.post(`${prefix}/signature/${guid}/severity/`, { severity, organization_id }).then(() => {});
}

export function cloneAnalytic(guid: Guid, organization_id: Ident): Promise<CloneResponse> {
  return snapattack.post(`${prefix}/signature/${guid}/clone/`, { organization_id }).then(r => r.data);
}

export function create(analytic: Partial<AnalyticCreate>, wait = false): Promise<Artifact> {
  return snapattack
    .post(`${prefix}/signature/`, analytic, wait ? { params: { wait_for_compilation: true } } : null)
    .then(r => r.data);
}

export function erase(guid: Guid): Promise<any> {
  return snapattack.delete(`${prefix}/signature/${guid}/`).then(r => r.data);
}

export function filterBlueMarkers(
  analyticGuid: Guid,
  logState: AnalyticLogState,
  page = 0,
  size = 50
): Promise<Page<BlueMarker>> {
  const filterPayload = {
    op: Ops.equals,
    field: logState,
    value: 'true'
  };
  const params = { page, size };
  return snapattack
    .post(`${prefix}/signature/${analyticGuid}/markers/blue/`, filterPayload, { params })
    .then(r => r.data);
}

export function getRelatedSessions(tag: Guid): Promise<RelatedSession[]> {
  return snapattack.get<RelatedSession[]>(`${prefix}/signature/${tag}/session/`).then(r => r.data);
}

export function publish(guid: Guid): Promise<any> {
  return snapattack
    .put(`${prefix}/signature/${guid}/visibility/`, { visibility: Visibility.Published })
    .then(r => r.data);
}

export function save(guid: Guid, analytic: Partial<AnalyticCreate>, wait = false): Promise<Artifact> {
  return snapattack
    .put(`${prefix}/signature/${guid}/`, analytic, wait ? { params: { wait_for_compilation: true } } : null)
    .then(r => r.data);
  // .catch(err => (err.response.status === 400 ? err.response.data.errors : err.response.data.message));
}

export type UpdateWorkflowResponse = void | { detail: string };
// The state always has to have an org attached because state is per org
// So even though the analytic belongs to orgA, state could be different for orgB.
// Why/how does this work with our current revision model?
export function updateWorkflow(
  guid: Guid,
  state: WorkflowState,
  organization_id: Ident
): Promise<UpdateWorkflowResponse> {
  return snapattack
    .post(`${prefix}/signature/${guid}/state/`, {
      state,
      organization_id
    })
    .then(res => res.data);
}

export function deployAnalytic(guid: Guid, payload: AnalyticDeploymentPayload): Promise<AnalyticDeploymentDetail> {
  return snapattack.post(`${prefix}/signature/${guid}/deployment/`, payload).then(r => r.data);
}

export function removeAnalyticDeployment(guid: Guid, deployment_id: Ident, integration_guid?: Guid): Promise<void> {
  let url = `${prefix}/signature/${guid}/deployment/${deployment_id}/`;
  if (integration_guid) {
    url += `${integration_guid}/`;
  }
  return snapattack.delete(url);
}

export function getSigmaBackends(engine: SigmaEngine): Promise<SigmaBackend[]> {
  return snapattack
    .get(`${prefix}/translate/backends/available/`, {
      params: {
        engine
      }
    })
    .then(r => r.data);
}

export function getConfigs(configType: ConfigType): Promise<SigmaConfig[]> {
  return snapattack.get(`${prefix}/translate/${configType}/`).then(res => res.data);
}

function getConfigPayload(configYaml: string, organization_id: Ident): FormData {
  const fd = new FormData();
  fd.append('payload', JSON.stringify({ organization_id }));
  fd.append('file', new Blob([configYaml]));
  return fd;
}

export async function postConfig(
  configType: ConfigType,
  configYaml: string,
  organization_id: Ident
): Promise<SigmaConfig> {
  const payload = getConfigPayload(configYaml, organization_id);
  return await snapattack.post(`${prefix}/translate/${configType}/`, payload).then(r => r.data);
}

export async function putConfig(
  configType: ConfigType,
  sigma_identifier: string,
  configYaml: string,
  organization_id: Ident
): Promise<void> {
  const payload = getConfigPayload(configYaml, organization_id);
  return await snapattack.put(`${prefix}/translate/${configType}/${sigma_identifier}/`, payload);
}

export function deleteConfig(configType: ConfigType, sigma_identifier: string): Promise<void> {
  return snapattack.delete(`${prefix}/translate/${configType}/${sigma_identifier}/`);
}

/*
 * Not a true bulk deploy. Endpoint requires a list of items to deploy.
 * Nope.. only crawling here. Otherwise we need to aggregate all the data
 * and then send a massive list. :/
 */
type Body = {
  organization_id: number;
  analytics: Guid[];
  overwrite: boolean;
  integrations: Guid[];
  options?: Record<Guid, unknown>;
};
export type BulkDeployResponse = { analytic_version_id: number; deployment_id: number }[];
export function bulkDeploy(
  organizationId: Ident,
  analytics: Guid[],
  integrations: Guid[],
  options?: Record<Guid, unknown>
): Promise<BulkDeployResponse> {
  const body = {
    organization_id: organizationId,
    analytics,
    overwrite: false,
    integrations
  } as Body;
  if (options) {
    body.options = options;
  }

  return snapattack.post(`${prefix}/signature/bulk/deployment/`, body).then(r => r.data);
}

export function bulkUnDeploy(
  organizationId: Ident,
  analytics: Guid[],
  integrations?: Guid[]
): Promise<BulkDeployResponse> {
  const body = {
    organization_id: organizationId,
    analytics
  } as Body;
  if (integrations) {
    body.integrations = integrations;
  }

  return snapattack.delete(`${prefix}/signature/bulk/deployment/`, { data: body }).then(r => r.data);
}

export function quickAddTags(guid: Guid, additions: string[]) {
  return snapattack.put(`${prefix}/signature/${guid}/tags/`, additions);
}

export function quickDeleteTags(guid: Guid, deletions: string[]) {
  return snapattack.delete(`${prefix}/signature/${guid}/tags/`, { data: deletions });
}

export function tagRefresh(guid: Guid): Promise<TagGroup> {
  return snapattack.get(`${prefix}/signature/${guid}/tags/`).then(r => convertTagsToTagNames(r.data));
}
