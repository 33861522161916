import mean from 'lodash/mean';

import { BarChartProps } from 'snap-ui/Charts/BarChart';

import { AttackNode } from 'module/Matrix/Matrix.type';
import { SecurityProfileTag, TagWeight } from 'module/SecurityProfile';
import { Discriminator } from 'module/Tag';
import { ScoreBaseMap } from 'module/Widgets/StateLabel';

import { Artifact, Guid, Ident } from 'types/common';
import { Entry } from 'types/core';

import {
  AggregatedAttackCoverage,
  AggregatedProfileCoverage,
  ScoreAggregation,
  TagCoverage,
  RawValues,
  Calculated,
  CoverageStatName
} from './type';

export function decimalToPercent(value: number): number {
  return Math.round((value as number) * 100);
}

export function convertToPercent(value: number): string {
  return decimalToPercent(value) + '%';
}

function average(nums: RawValues): Calculated {
  const count = nums.length;
  const average = count ? Math.floor(mean(nums) * 100) / 100 : 0;
  return { average, count: nums.length };
}

export function emptyStats(): ScoreAggregation<Calculated>;
export function emptyStats(asArray: true): ScoreAggregation<RawValues>;
export function emptyStats(asArray?: true): ScoreAggregation<Calculated | RawValues> {
  return Object.values(TagWeight).reduce(
    (s, score) => ({
      ...s,
      [score]: {
        coverage: asArray ? [] : { average: 0, count: 0 },
        depth: asArray ? [] : { average: 0, count: 0 },
        breadth: asArray ? [] : { average: 0, count: 0 }
      }
    }),
    {}
  ) as ScoreAggregation;
}

export function aggregateAttackCoverage(
  tactics: AttackNode[],
  _getCoverage: (id: Ident) => TagCoverage,
  _getProfile: (id: Ident) => SecurityProfileTag
): AggregatedAttackCoverage {
  const getCoverage = (n: AttackNode) => _getCoverage(n.id);
  const getProfile = (n: AttackNode) => _getProfile(n.id);

  /** this will be mutated */
  const rawTacticStats = emptyStats(true);
  /** this will be mutated */
  const rawTechniqueStats = emptyStats(true);
  /** this will be mutated */
  const rawSubtechniqueStats = emptyStats(true);

  tactics.forEach(tactic => {
    processNode(tactic, rawTacticStats, getCoverage, getProfile);
    tactic.attack_children.forEach(technique => {
      processNode(technique, rawTechniqueStats, getCoverage, getProfile);
      technique.attack_children?.forEach(subtechnique => {
        processNode(subtechnique, rawSubtechniqueStats, getCoverage, getProfile);
      });
    });
  });

  return {
    tactics: reduceToAverages(rawTacticStats),
    techniques: reduceToAverages(rawTechniqueStats),
    subtechniques: reduceToAverages(rawSubtechniqueStats)
  };
}

export function aggregateProfileCoverage(
  actors: Artifact[],
  attacks: Artifact[],
  software: Artifact[],
  vulnerabilities: Artifact[],
  _getCoverage: (tagType: Discriminator, guid: Guid) => TagCoverage,
  _getProfile: (tagType: Discriminator, guid: Guid) => SecurityProfileTag
): AggregatedProfileCoverage {
  const getCoverage = (t: Discriminator) => (n: Artifact) => _getCoverage(t, n.guid);
  const getProfile = (t: Discriminator) => (n: Artifact) => _getProfile(t, n.guid);

  return (
    [
      [actors, Discriminator.Actor],
      [attacks, Discriminator.Attack],
      [software, Discriminator.Software],
      [vulnerabilities, Discriminator.Vulnerability]
    ] as const
  ).reduce((agg, [tagList, tagType]) => {
    /** this will be mutated */
    const rawStats = emptyStats(true);
    tagList.forEach(tag => {
      processNode(tag, rawStats, getCoverage(tagType), getProfile(tagType));
    });

    return {
      ...agg,
      [tagType]: reduceToAverages(rawStats)
    };
  }, {} as AggregatedProfileCoverage);

  /** this will be mutated */
  const rawActorStats = emptyStats(true);
  /** this will be mutated */
  const rawAttackStats = emptyStats(true);
  /** this will be mutated */
  const rawSoftwareStats = emptyStats(true);
  /** this will be mutated */
  const rawVulnerabilityStats = emptyStats(true);

  actors.forEach(actor => {
    processNode(actor, rawActorStats, getCoverage(Discriminator.Actor), getProfile(Discriminator.Actor));
  });

  attacks.forEach(attack => {
    processNode(attack, rawAttackStats, getCoverage(Discriminator.Attack), getProfile(Discriminator.Attack));
  });

  software.forEach(software => {
    processNode(software, rawSoftwareStats, getCoverage(Discriminator.Software), getProfile(Discriminator.Software));
  });

  vulnerabilities.forEach(vulnerability => {
    processNode(
      vulnerability,
      rawVulnerabilityStats,
      getCoverage(Discriminator.Vulnerability),
      getProfile(Discriminator.Vulnerability)
    );
  });

  return {
    [Discriminator.Actor]: reduceToAverages(rawActorStats),
    [Discriminator.Attack]: reduceToAverages(rawAttackStats),
    [Discriminator.Software]: reduceToAverages(rawSoftwareStats),
    [Discriminator.Vulnerability]: reduceToAverages(rawVulnerabilityStats)
  };
}

function processNode<N extends AttackNode | Artifact>(
  node: N,
  stats: ScoreAggregation<RawValues>,
  getCoverage: (node: N) => TagCoverage,
  getProfile: (node: N) => SecurityProfileTag
) {
  const coverage = getCoverage(node);
  const priorityScore = getProfile(node)?.score_label;
  pushValues(stats, coverage, priorityScore);
}

function pushValues(stats: ScoreAggregation<RawValues>, coverage: TagCoverage, score: TagWeight) {
  if (!score || score === TagWeight.Ignored) return;
  stats[score].coverage.push(coverage?.score_coverage || 0);
  stats[score].breadth.push(coverage?.score_breadth || 0);
  stats[score].depth.push(coverage?.score_depth || 0);
}

function reduceToAverages(stats: ScoreAggregation<RawValues>): ScoreAggregation<Calculated> {
  const averages: ScoreAggregation<Calculated> = Object.fromEntries(
    Object.entries(stats).map(([score, valuesByStat]: Entry<ScoreAggregation<RawValues>>) => [
      score,
      Object.fromEntries(
        Object.entries(valuesByStat).map(([statName, rawValues]: [CoverageStatName, RawValues]) => [
          statName,
          average(rawValues)
        ])
      )
    ])
  ) as ScoreAggregation;

  return averages;
}

export function mapAggregationToChartData(
  aggregation: ScoreAggregation,
  metric: CoverageStatName,
  palette
): BarChartProps['data'] {
  return Object.values(TagWeight)
    .filter(v => v !== TagWeight.Ignored)
    .map(score => {
      const average = aggregation?.[score]?.[metric]?.average ?? 0;
      const coverage = Math.floor(average * 100);
      return {
        key: score,
        color: palette[ScoreBaseMap[score]?.color]?.main,
        label: score,
        value: coverage
      };
    });
}
