import { Integration } from 'module/Integration/Integration.type';
import { SupplementalArtifact } from 'module/Search/Search.type';
import { TagOption } from 'module/Tag/useTagOptions';

import { Entity, Permitted } from './auth';
import { Artifact, BooleanString, Guid, Ident, Named, Timestamp, Tracked } from './common';

export enum CompilationTargetId {
  Sigma = 1,
  SnapAttackSplunk = 2,
  SnapAttackZeek = 3,
  SnapAttackWindows = 4,
  SnapAttackCloud = 5,
  SnapAttackSplunkLinux = 6
}

export const TestableAnalyticCompilationTargets = [
  CompilationTargetId.SnapAttackSplunk,
  CompilationTargetId.SnapAttackZeek,
  CompilationTargetId.SnapAttackWindows,
  CompilationTargetId.SnapAttackCloud,
  CompilationTargetId.SnapAttackSplunkLinux
];

export interface AnalyticStateDetail {
  /** date */
  creation: string;
  /** date */
  modified: string;
  analytic_version_id: number;
  state: WorkflowState;
  created_by?: Named;
  is_current: boolean;
  organization_id: Ident;
}

export type AnalyticParentNode = {
  fork_version_id: Ident;
  guid: Guid;
  id: Ident;
  latest_modified: Timestamp;
  latest_version_id: Ident;
  name: string;
};

export interface AnalyticDeploymentDetail {
  id: Ident;
  creation: Timestamp;
  modified: Timestamp;
  analytic_version_id: number;
  is_current: boolean;

  created_by?: Named;
  organization_id?: Ident;
  integrations?: { guid: Guid }[];
  success_deployment?: { guid: Guid }[];
  failed_deployment?: { guid: Guid }[];
  pending_deployment?: { guid: Guid }[];
  status?: {
    integration: Guid;
    success: boolean;
    error_message?: string;
  }[];
}

/* Integration augmented with info about the Analytic's Languages and Deployments */
export type DeploymentIntegration = Integration & {
  deployment?: AnalyticDeploymentDetail;
  compatible: boolean;
};

export type AnalyticDeploymentPayload = {
  overwrite?: boolean;
  organization_id: Ident;
  integrations: Guid[];
  options?: Record<Guid, unknown>; // Integration Guid
};

export interface Analytic extends Artifact, SupplementalArtifact, AnalyticReferences {
  raw?: string;
  pinned_events?: Guid[];
}

export type LinkedThreatIntelligence = Named &
  Tracked & {
    uri: string;
  };

export type IntelMeta = Named &
  Tracked & {
    id: Ident;
  };

export type AnalyticReferences = {
  linked_threat_intelligence: LinkedThreatIntelligence[];
  references: string[];
  intel: IntelMeta[];
};

export enum WorkflowState {
  Development = 'Development',
  Testing = 'Testing',
  Verified = 'Verified',
  Rejected = 'Rejected'
}

export type Workflow = {
  state?: AnalyticStateDetail;
  states?: AnalyticStateDetail[];
  deployment?: AnalyticDeploymentDetail;
  deployments?: AnalyticDeploymentDetail[];
};

interface CommonCreateAnalytic {
  license_url?: string;
  organization_id: Ident;
  pinned_events?: Guid[];
  parent_analytic_guid?: Guid;
}

export interface SigmaAnalyticCreate extends CommonCreateAnalytic {
  raw: string;
}

export interface NativeAnalyticCreate extends CommonCreateAnalytic {
  source_analytic_compilation_target_id: number;
  author: string;
  name: string;
  description: string;
  raw: string;
  attack_names: string[];
  actor_names: string[];
  software_names: string[];
  vulnerability_names: string[];
  references: string[];
  license_url?: string;
  organization_id: Ident;
}

export type AnalyticCreate = SigmaAnalyticCreate | NativeAnalyticCreate;

export function isNativeAnalyticCreate(payload: Partial<AnalyticCreate>): payload is NativeAnalyticCreate {
  return 'source_analytic_compilation_target_id' in payload;
}

export interface FieldOption {
  datatype: string;
  description: string;
  field: string;
  event_ids: number[];
  prevalence: 'High' | 'Medium' | 'Low';
}

export interface LogsourceCategory {
  category: string;
  fields: FieldOption[];
}

export interface LogsourceSource {
  source: LogsourceType;
  categories: LogsourceCategory[];
}

export enum AnalyticTabType {
  Detection = 'detection',
  Metadata = 'metadata',
  Advanced = 'advanced'
}

export enum SigmaModifier {
  Contains = 'contains',
  EndsWith = 'endswith',
  StartsWith = 'startswith'
}

export enum SpecialSigmaModifier {
  All = 'all',
  Equal = 'equal'
}

export type SigmaLogsource = {
  product?: string;
  service?: string;
  category?: string;
};

export enum LogsourceType {
  Sysmon = 'sysmon'
}

export interface AnalyticForm {
  organization_id: number;
  logsource: SigmaLogsource & { [extra: string]: any };
  detection: DetectionForm;
  title: string;
  description: string;
  attack_names: TagOption[];
  actor_names: TagOption[];
  software_names: TagOption[];
  vulnerability_names: TagOption[];
  references: string[];
  languageId: number;
  license_url?: string;
  [extra: string]: any;
  parent_analytic_guid?: Guid;
}

export type CustomizationForm = {
  detection: AnalyticForm['detection'];
  raw?: string;
};

export type CustomizationFormUpdate =
  | {
      detection: AnalyticForm['detection'];
      raw?: never;
    }
  | {
      detection?: never;
      raw: string;
    };

export interface DetectionForm {
  condition: string;
  sections: SectionForm[];
}

export enum SectionKind {
  KeyValue = 'keyvalue',
  Strings = 'strings'
}

export interface SectionForm {
  kind: SectionKind;
  name: string;
  rules: KeyValueRule[]; // used if SectionKind.KeyValue
  values: string[]; // used if SectionKind.Strings
}

export type LanguageAdvanceConfig = {
  field?: string;
  value?: string;
};

export interface KeyValueRule {
  all: BooleanString;
  field: string;
  modifier: SigmaModifier | SpecialSigmaModifier.Equal;
  values: string[];
}

export enum AnalyticLogState {
  VALIDATED = 'validated',
  UNVALIDATED = 'unvalidated',
  VALIDATED_GAPS = 'gap',
  UNTESTED = 'untested'
}

export interface CloneResponse {
  guid: Guid;
}

export interface Language {
  id: Ident;
  name: string;
  hidden: boolean;
  config_updated: Timestamp;
  last_recompile: Timestamp | null;
  needs_recompile: boolean;
  currently_recompiling: boolean;
  output_format?: string;
  native_key?: string;
}

export interface LanguageExtended extends Language, Permitted {
  discriminator: SigmaEngine;
  config_keys: string[];
  sort_index: number;
  translate_configs: SigmaConfig[];
  organization: Entity;
  backend_key: string;
  backend_options?: string[];
}

export type EditorLanguage = Pick<LanguageExtended, 'discriminator' | 'name' | 'output_format' | 'config_keys'> & {
  id?: LanguageExtended['id'];
  organization_id: Ident;
  _clone_configs?: boolean;
  _source_language_id?: Ident;
  backend_key: string;
  backend_options: LanguageAdvanceConfig[];
};

export const SigmaEngine = {
  Legacy: 'legacy',
  PySigma: 'pysigma'
};

export type SigmaEngine = (typeof SigmaEngine)[keyof typeof SigmaEngine];

export type LanguageTargetPayload = {
  discriminator: SigmaEngine;
  organization_id: Ident;
  name: string;
  backend_key: string;
  output_format?: string;
  config_keys: string[];
  backend_options?: string[];
};

export interface AnalyticLanguage extends Language {
  supported: boolean;
}

export enum LanguageId {
  Sigma = 1,
  SnapAttackSplunk = 2,
  Zeek = 3,
  Windows = 4,
  Cloud = 5
}

export interface RelatedSession {
  name: string;
  guid: Guid;
}

export const ConfigType = {
  Legacy: 'field_mapping',
  Pipeline: 'pipeline',
  Validator: 'validator'
} as const;

export type ConfigType = (typeof ConfigType)[keyof typeof ConfigType];

export type SigmaConfig = Permitted & {
  id: Ident;
  name: string;
  organization_id: Ident;
  organization: Entity;
  sigma_identifier: string;
  raw?: string;
  creation: string;
  modified: string;
  discriminator: ConfigType;
};

export type EditorConfig = Partial<
  Pick<SigmaConfig, 'discriminator' | 'id' | 'name' | 'organization_id' | 'raw' | 'sigma_identifier'>
>;

export type SigmaConfigMap = {
  name: string;
  sigma_identifier: string;
};

export type AnalyticRecommendation = SupplementalArtifact;

export interface ArtifactAnalyticCatalog extends Artifact {
  deployments?: any;
  languageList?: AnalyticLanguage[];
}

export type SigmaBackend = {
  backend_key: string;
  output_formats?: string[];
};
