import React from 'react';

import pick from 'lodash/pick';
import { useHistory } from 'react-router-dom';

import useAnalyticTranslation from 'aso/useAnalyticTranslation';

import Path from 'constants/paths';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { useAnalyticLanguageCatalog } from 'module/Analytic/AnalyticLanguages';
import { useAnalyticCatalog } from 'module/Analytic/core/AnalyticProvider';
import { useAnalyticVersionCatalog } from 'module/Analytic/core/AnalyticVersionProvider';
import useEditorStateContext, { getNativeCloneState } from 'module/Analytic/core/EditorStateProvider';
import UndeployOnClone from 'module/Analytic/core/UndeployOnClone';
import {
  getSyntheticSnapattackIntegration,
  useIntegrationOptions,
  useLanguageOptions,
  useLanguagePlatforms
} from 'module/Integration';
import { mapIntegrationToOption } from 'module/Integration/Integration.util';
import { IntegrationOption } from 'module/Integration/IntegrationAutocomplete';
import { useMayI } from 'module/May';
import { SupplementalArtifact } from 'module/Search';

import { useAuth, useIntegrationCatalog, useLanguageContext } from 'provider';

import { checkContentPermission } from 'services/authService';

import { Status } from 'storage';

import { CompilationTargetId } from 'types/analytic';
import { ContentPermission, FunctionalPermission } from 'types/auth';
import { Artifact, Guid, Ident } from 'types/common';

import { getIntegrationValueFromLanguageId, TranslateAnalytic } from './';

interface ViewAnalyticWrapperProps {
  analytic: Artifact & { raw?: string };
  analyticGuid: Guid;
  supplemental: SupplementalArtifact;
}

export default function ViewAnalyticWrapper(props: ViewAnalyticWrapperProps): JSX.Element {
  const { push } = useHistory();
  const { defaultLanguageId, defaultOrgId } = useAuth();
  const { integrations } = useIntegrationCatalog();
  const { allData: allLanguages } = useLanguageContext();
  const { getPlatformDetails, supportedPlatforms: snapattackSupportedPlatforms } = useLanguagePlatforms();
  const { getIntegrationOptionsFromPlatformEntries, integrationOptions: sigmaDetectionIntegrationOptions } =
    useIntegrationOptions(true, props.analytic.analytic_compilation_targets);

  const { isLoaded, state } = useEditorStateContext();
  const [{ permissions }] = useAnalyticCatalog();
  const { status: versionsStatus, errorProps: versionsErrorProps } = useAnalyticVersionCatalog(); // needed native analytic code
  const { data: languageList, status: languageStatus, getLanguageById } = useAnalyticLanguageCatalog();

  const canCreate = useMayI(FunctionalPermission.CreateAnalytic);
  const canEdit = checkContentPermission(permissions, ContentPermission.Edit);
  const isNative =
    props.supplemental?.source_analytic_compilation_target_id &&
    props.supplemental?.source_analytic_compilation_target_id !== CompilationTargetId.Sigma;

  const [selectedLanguageId, setSelectedLanguageId] = React.useState<number>();
  const [selectedIntegrationOptionValue, setSelectedIntegrationOptionValue] = React.useState<string>('');
  const [undeployState, setUndeployState] = React.useState<{ showDialog: boolean; languageId?: number; raw?: string }>({
    showDialog: false
  });

  const {
    translation,
    error: translationError,
    status: translationStatus
  } = useAnalyticTranslation(props.analytic.guid, props.analytic?.is_native ? null : selectedLanguageId);
  const translationIsPending = translationStatus === Status.pending;

  const getLanguageName = React.useCallback(
    (id: Ident): string => {
      return languageList.find(l => l.id === id)?.name ?? 'Unknown Language';
    },
    [languageList]
  );

  const integrationOptions: IntegrationOption[] = React.useMemo(() => {
    if (isNative) {
      const detectionSupportedPlatforms = getPlatformDetails(props.supplemental?.source_analytic_compilation_target_id);
      const integrationOptions = detectionSupportedPlatforms?.platforms
        .filter(p => !!p.integration)
        .map(p => mapIntegrationToOption(p.integration));
      // we have at least one integration that supports this detection. show the integrations
      if (integrationOptions?.length) return integrationOptions;
      if (detectionSupportedPlatforms?.platforms.length) {
        // user doesn't have integrations but snapattack has some that suppoort the language
        return getIntegrationOptionsFromPlatformEntries(
          Object.entries(
            pick(
              snapattackSupportedPlatforms,
              detectionSupportedPlatforms.platforms.map(p => p.integrationType)
            )
          ),
          true
        );
      } else {
        // native detection with no integration support within SnapAttack
        const id = props.supplemental?.source_analytic_compilation_target_id;
        const name = getLanguageName(id);
        return [
          {
            label: name,
            type: name,
            value: `language-${id}`
          }
        ];
      }
      return [];
    } else {
      // sigma detection
      return sigmaDetectionIntegrationOptions;
    }
  }, [
    isNative,
    getPlatformDetails,
    props.supplemental?.source_analytic_compilation_target_id,
    getIntegrationOptionsFromPlatformEntries,
    snapattackSupportedPlatforms,
    getLanguageName,
    sigmaDetectionIntegrationOptions
  ]);

  const languageOptions = useLanguageOptions(
    selectedIntegrationOptionValue,
    props.analytic.analytic_compilation_targets,
    props.analytic.recompile_in_progress
  ).sort((a, b) => -b.warning - -a.warning);
  const nativeLanguageOptions = allLanguages
    .filter(l => l.id === props.supplemental?.source_analytic_compilation_target_id)
    .map(l => ({
      value: l.id.toString(),
      content: l.name,
      label: l.name
    }));

  const selectedLanguage = isNative
    ? allLanguages.find(l => l.id === props.supplemental?.source_analytic_compilation_target_id)
    : getLanguageById(selectedLanguageId);

  React.useEffect(() => {
    if (props.analytic.analytic_compilation_targets) {
      if (defaultLanguageId && !isNative) {
        let defaultIntegration = '';
        if (props.analytic?.analytic_compilation_targets.includes(defaultLanguageId)) {
          defaultIntegration = getIntegrationValueFromLanguageId(
            defaultLanguageId,
            integrationOptions,
            [...integrations.all, getSyntheticSnapattackIntegration(allLanguages)],
            snapattackSupportedPlatforms
          );
        } else {
          defaultIntegration = `language-${CompilationTargetId.Sigma.toString()}`;
        }

        setSelectedIntegrationOptionValue(defaultIntegration);
      }
      // native detections fall down to here
      else if (integrationOptions[0]?.value) {
        setSelectedIntegrationOptionValue(integrationOptions[0]?.value);
      }
    }
  }, [
    allLanguages,
    defaultLanguageId,
    integrations.all,
    integrationOptions,
    isNative,
    snapattackSupportedPlatforms,
    props.analytic.analytic_compilation_targets
  ]);

  React.useEffect(() => {
    if (languageOptions?.some(l => l.value === defaultLanguageId?.toString() && !l.warning))
      setSelectedLanguageId(defaultLanguageId);
    else {
      const validLanguage = languageOptions.find(language => !language.warning)?.value;

      setSelectedLanguageId(parseInt(validLanguage ?? languageOptions[0]?.value));
    }
  }, [defaultLanguageId, languageOptions]);

  function handleClone(languageId: number, raw: string) {
    Engage.track(
      Fingerprint.of(Widget.TranslateAnalytic).withQualifier('clone as native').withData({
        guid: state.guid,
        languageId: languageId
      })
    );
    const nativeState = getNativeCloneState(state, defaultOrgId, props.analyticGuid, languageId, raw);
    push({ pathname: Path.IDE, state: { editor: nativeState } });
  }

  const handleEditAsNative =
    canCreate || (canCreate && !canEdit)
      ? (languageId: number, raw: string) => setUndeployState({ showDialog: true, languageId, raw })
      : undefined;

  function handleLanguageChange(languageId: number): void {
    setSelectedLanguageId(languageId);
  }

  if (!props.analytic?.guid) return null;

  if (isLoaded)
    return (
      <>
        <TranslateAnalytic
          analyticGuid={props.analytic.guid}
          analyticName={props.analytic.name}
          disableEditAsNative={!isLoaded || selectedLanguageId === CompilationTargetId.Sigma}
          editAsNativeAction='Clone'
          integrationOptions={integrationOptions}
          languageOptionStatus={languageStatus}
          languageOptions={isNative ? nativeLanguageOptions : languageOptions}
          onEditAsNative={handleEditAsNative}
          onIntegrationChange={setSelectedIntegrationOptionValue}
          onLanguageChange={handleLanguageChange}
          selectedIntegrationValue={selectedIntegrationOptionValue}
          selectedLanguage={selectedLanguage}
          native={isNative}
          translation={isNative ? props.analytic?.raw : translation}
          translationError={isNative ? versionsErrorProps?.messages.join('\n') : translationError}
          translationIsPending={isNative ? versionsStatus === Status.pending : translationIsPending}
        />
        <UndeployOnClone
          isOpen={undeployState?.showDialog}
          onClose={() => setUndeployState({ showDialog: false })}
          onComplete={() => handleClone(undeployState.languageId, undeployState.raw)}
        />
      </>
    );

  return null;
}
