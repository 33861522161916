import React from 'react';

import isEmpty from 'lodash/isEmpty';

import {
  deleteIntegration,
  getDeploymentSchemaCatalog,
  getIntegrations,
  postCloneIntegration,
  postIntegration,
  postIntegrationDeployment,
  putIntegration
} from 'module/Integration/Integration.api';
import {
  CreateIntegrationRequest,
  DeploymentSchemaCatalog,
  Integration,
  IntegrationFormData
} from 'module/Integration/Integration.type';

import { useAuth } from 'provider';

import { ErrorProps, Status, useAsync } from 'storage';

import { ContentPermission, Organization } from 'types/auth';
import { Guid } from 'types/common';

import { filterIntegrationsByOrg, filterIntegrationsByPermission } from './Integration.util';

export type IntegrationCatalog = {
  errorProps?: ErrorProps;
  integrations: {
    all: Integration[];
    preferred_org_aware: Integration[];
    editable: Integration[];
    deployable: Integration[];
    huntable: Integration[];
    huntableByOrgs: (orgIDs: Organization['id'][]) => Integration[];
    searchable: Integration[];
    preferredOrgSearchable: Integration[];
  };
  deploymentSchema: DeploymentSchemaCatalog;
  error?: string;
  refresh(): void;
  create(payload: IntegrationFormData): Promise<void>;
  update(guid: Guid, payload: IntegrationFormData): Promise<void>;
  remove(guid: Guid): Promise<void>;
  clone(guid: Guid, payload: CreateIntegrationRequest): Promise<void>;
  sync(guids: Guid[]): Promise<void[]>;
  status: Status;
  taskStatus: Status;
  syncStatus: Status;
  deploymentSchemaStatus: Status;
};

const registered = (integration: Integration) => integration.is_registered;

export default function useIntegrations(): IntegrationCatalog {
  const auth = useAuth();
  const { data, errorProps, run, status } = useAsync<Integration[]>([]);
  const { status: taskStatus, task } = useAsync();
  const { status: syncStatus, task: syncTask } = useAsync();
  const {
    data: deploymentSchema,
    run: deploymentSchemaRun,
    status: deploymentSchemaStatus
  } = useAsync<DeploymentSchemaCatalog>();

  React.useEffect(() => {
    deploymentSchemaRun(getDeploymentSchemaCatalog());
  }, [deploymentSchemaRun]);

  const refresh = React.useCallback(() => {
    if (auth.user.email) {
      run(getIntegrations());
    }
  }, [run, auth.user.email]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const create = React.useCallback(
    (payload: IntegrationFormData) => {
      return task(postIntegration(payload).then(refresh));
    },
    [refresh, task]
  );

  const update = React.useCallback(
    (guid: Guid, payload: IntegrationFormData) => {
      return task(putIntegration(guid, payload).then(refresh));
    },
    [refresh, task]
  );

  const remove = React.useCallback(
    (guid: Guid) => {
      return task(deleteIntegration(guid).then(refresh));
    },
    [refresh, task]
  );

  const clone = React.useCallback(
    (guid: Guid, payload: CreateIntegrationRequest) => {
      return task(postCloneIntegration(guid, payload).then(refresh));
    },
    [refresh, task]
  );

  const sync = React.useCallback(
    (guids: Guid[]) => {
      const promises = guids.map(guid => syncTask(postIntegrationDeployment(guid)));
      return Promise.all(promises);
    },
    [syncTask]
  );

  const preferred_org_aware = React.useMemo(
    () => filterIntegrationsByOrg(data, [auth.defaultOrgId]),
    [auth.defaultOrgId, data]
  );

  const editable = React.useMemo(() => filterIntegrationsByPermission(data, ContentPermission.Edit), [data]);

  const deployable = React.useMemo(
    () => preferred_org_aware.filter(registered).filter(integration => !isEmpty(integration.deployment_targets)),
    [preferred_org_aware]
  );

  const huntable = React.useMemo(
    () => preferred_org_aware.filter(registered).filter(integration => !isEmpty(integration.hunt_targets)),
    [preferred_org_aware]
  );

  const preferredOrgSearchable = React.useMemo(
    () => preferred_org_aware.filter(registered).filter(integration => !isEmpty(integration.search_targets)),
    [preferred_org_aware]
  );

  const huntableByOrgs = React.useCallback(
    (organizationIDs: Organization['id'][]) =>
      filterIntegrationsByOrg(data, organizationIDs)
        .filter(registered)
        .filter(integration => !isEmpty(integration.hunt_targets)),
    [data]
  );

  // can use community things
  const searchable = React.useMemo(
    () =>
      data
        .filter(integration => !isEmpty(integration.search_targets))
        .filter(integration => Boolean(integration.url_template)),
    [data]
  );

  return {
    integrations: {
      all: data,
      preferred_org_aware,
      editable,
      deployable,
      huntable,
      huntableByOrgs,
      searchable,
      preferredOrgSearchable
    },
    deploymentSchema,
    errorProps,
    refresh,
    create,
    update,
    remove,
    clone,
    sync,
    status,
    taskStatus,
    syncStatus,
    deploymentSchemaStatus
  };
}
