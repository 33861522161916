import React from 'react';

import ReactDiffViewer from 'react-diff-viewer-continued';

import { Option, getDisplayValue } from 'snap-ui/Autocomplete';
import Placeholder from 'snap-ui/Placeholder';
import { useTheme } from 'snap-ui/util';

import useRawTranslation from 'aso/useRawTranslation';

import { IDETranslationStateProvider } from 'module/IDE';
import {
  getSyntheticSnapattackIntegration,
  useIntegrationOptions,
  useLanguageOptions,
  useLanguagePlatforms
} from 'module/Integration';
import { IntegrationOption } from 'module/Integration/Integration.type';
import { Page } from 'module/Layout/Styled';
import {
  StyledAutocomplete,
  StyledAutocompletePaper,
  StyledIntegrationAutocomplete
} from 'module/TranslateAnalytic/TranslateAnalytic.helper';
import { getIntegrationValueFromLanguageId } from 'module/TranslateAnalytic/TranslateAnalytic.util';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Status } from 'storage';

import { CompilationTargetId, DeploymentIntegration } from 'types/analytic';

import { getQueryParam } from 'utilities/SearchParam';
import { STANDARD, formatCustomTimestamp } from 'utilities/TimeUtils';

import { StyledToolbar, VersionDeployButton, VersionDiff } from '../Analytic.style';
import { AnalyticRouterProps, AnalyticVersion } from '../Analytic.type';
import { useAnalyticLanguageCatalog } from '../AnalyticLanguages';
import AnalyticVersionList from '../AnalyticVersion/AnalyticVersionList';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import { useAnalyticVersionCatalog } from '../core/AnalyticVersionProvider';
import { DeployIntegrationButton } from '../core/MetadataPanel/Deployment';
import { getCompatibleIntegration } from '../core/MetadataPanel/util';
import { AnalyticDiffSelector } from './AnalyticDiffSelector';

export function AnalyticDiff(props: AnalyticRouterProps) {
  const pushSnack = usePushSnack();
  const { palette } = useTheme();
  const deployedEnvironment = getQueryParam(location.search, 'deployedEnvironment');
  const leftId = props.match.params.versionId;
  const rightId = props.match.params.otherId;
  const { defaultLanguageId, defaultOrgId } = useAuth();
  const [{ analytic, analyticStatus, supplemental, supplementalRefresh }] = useAnalyticCatalog();

  const { integrations, status: integrationsStatus } = useIntegrationCatalog();
  const { status: versionStatus, versions: allVersion } = useAnalyticVersionCatalog();

  const { supportedPlatforms: snapattackSupportedPlatforms } = useLanguagePlatforms();
  const { integrationOptions: sigmaDetectionIntegrationOptions } = useIntegrationOptions(
    true,
    analytic.analytic_compilation_targets
  );

  const {
    data: languageList,
    allLanguages,
    status: languageListStatus,
    getLanguageById
  } = useAnalyticLanguageCatalog();

  const [selectedLanguageId, setSelectedLanguageId] = React.useState<number>();
  const [selectedIntegrationOptionValue, setSelectedIntegrationOptionValue] = React.useState<string>('');
  const [isTranslated, setIsTranslated] = React.useState<boolean>(false);

  const previousLanguageId = React.useRef<number>();
  const previousLeftId = React.useRef<string>();
  const previousRightId = React.useRef<string>();

  React.useEffect(() => {
    previousLanguageId.current = selectedLanguageId;
  });

  React.useEffect(() => {
    previousLeftId.current = leftId;
  });

  React.useEffect(() => {
    previousRightId.current = rightId;
  });

  const leftVersion = allVersion?.find(version => version.id.toString() === leftId);
  const rightVersion = allVersion?.find(version => version.id.toString() === rightId);
  const orgDeployments = supplemental?.deployments?.filter(d => d.organization_id === defaultOrgId);

  const deployableIntegrations: DeploymentIntegration[] = integrations.deployable.map(integration => {
    return {
      ...integration,
      deployment: orgDeployments?.find(dep => dep.integrations?.some(i => i.guid === integration.guid)),
      compatible: getCompatibleIntegration(supplemental, languageList, integration)
    };
  });
  const deployedIntegration = deployableIntegrations.find(integration => integration.guid === deployedEnvironment);

  const shouldTranslateLeft = React.useMemo(() => {
    if (selectedLanguageId !== previousLanguageId.current) return true;
    if (leftId !== previousLeftId.current && leftVersion?.raw) return true;
    return false;
  }, [selectedLanguageId, leftId, leftVersion]);
  const shouldTranslateRight = React.useMemo(() => {
    if (selectedLanguageId !== previousLanguageId.current) return true;
    if (rightId !== previousRightId.current && rightVersion?.raw) return true;
    return false;
  }, [selectedLanguageId, rightId, rightVersion]);

  const {
    translation: leftTranslation,
    status: leftTranslationStatus,
    error: leftTranslationError
  } = useRawTranslation(shouldTranslateLeft, leftVersion?.raw, null, selectedLanguageId);
  const {
    translation: rightTranslation,
    status: rightTranslationStatus,
    error: rightTranslationError
  } = useRawTranslation(shouldTranslateRight, rightVersion?.raw, null, selectedLanguageId);

  const isNative = analytic.is_native;
  const isPending =
    [languageListStatus, integrationsStatus, analyticStatus].some(s => s === Status.pending) && !!supplemental;

  const integrationOptions: IntegrationOption[] = React.useMemo(
    () => sigmaDetectionIntegrationOptions,
    [sigmaDetectionIntegrationOptions]
  );

  const languageOptions = useLanguageOptions(
    selectedIntegrationOptionValue,
    analytic.analytic_compilation_targets,
    analytic.recompile_in_progress
  ).sort((a, b) => -b.warning - -a.warning);

  const selectedLanguage = getLanguageById(selectedLanguageId);

  React.useEffect(() => {
    if (defaultLanguageId && !isNative && analytic?.analytic_compilation_targets) {
      let defaultIntegration = '';
      if (defaultLanguageId in analytic.analytic_compilation_targets) {
        defaultIntegration = getIntegrationValueFromLanguageId(
          defaultLanguageId,
          integrationOptions,
          [...integrations.all, getSyntheticSnapattackIntegration(allLanguages)],
          snapattackSupportedPlatforms
        );
      } else {
        defaultIntegration = `-${CompilationTargetId.Sigma.toString()}`;
      }

      setSelectedIntegrationOptionValue(defaultIntegration);
    }
  }, [
    allLanguages,
    defaultLanguageId,
    integrations.all,
    integrationOptions,
    isNative,
    snapattackSupportedPlatforms,
    analytic.analytic_compilation_targets
  ]);

  React.useEffect(() => {
    if (languageOptions?.some(l => l.value === defaultLanguageId?.toString() && !l.warning)) {
      setSelectedLanguageId(defaultLanguageId);
    } else if (languageOptions.length > 0) {
      const validLanguage = languageOptions.find(language => !language.warning)?.value;
      setSelectedLanguageId(parseInt(validLanguage ?? languageOptions[0]?.value));
    }
  }, [defaultLanguageId, languageOptions]);

  const disableIntegrationOption = integrationOptions.length <= 1;
  const disableLanguageOption = languageOptions.length <= 1;

  const leftContent = React.useMemo(() => {
    if (isTranslated) {
      if (leftId === previousLeftId.current) {
        return leftTranslation || '';
      } else {
        return '';
      }
    }
    return leftVersion?.raw || '';
  }, [isTranslated, leftId, leftTranslation, leftVersion?.raw]);

  const rightContent = React.useMemo(() => {
    if (isTranslated) {
      if (rightId === previousRightId.current) {
        return rightTranslation || '';
      } else {
        return '';
      }
    }
    return rightVersion?.raw || '';
  }, [isTranslated, rightId, rightTranslation, rightVersion?.raw]);

  React.useEffect(() => {
    if (leftTranslationError) pushSnack(leftTranslationError, 'warning', 'center', 'bottom');
    if (rightTranslationError) pushSnack(rightTranslationError, 'warning', 'center', 'bottom');
  }, [leftTranslationError, rightTranslationError, pushSnack]);
  const existingAnalytic = allVersion.filter(v => v.guid === supplemental?.guid);
  const parentAnalytic = allVersion.filter(v => v.guid === supplemental?.parent?.guid);
  const isDeployable = allVersion?.filter(v => v.guid === supplemental?.guid)?.[0]?.id === rightVersion?.id;

  function filterByCurrentOrParentVersions(version?: AnalyticVersion): AnalyticVersion[] {
    if (existingAnalytic.some(c => c.id === version?.id)) {
      return existingAnalytic;
    } else {
      return parentAnalytic;
    }
  }

  function handleIntegrationChange(value: string) {
    setIsTranslated(true);
    setSelectedIntegrationOptionValue(value);
  }

  function handleLanguageChange(value: string | Option[]) {
    setIsTranslated(true);
    setSelectedLanguageId(Number.parseInt(value as string, 10));
  }

  return (
    <IDETranslationStateProvider>
      <VersionDiff>
        <AnalyticVersionList
          open={true}
          versions={allVersion}
          versionStatus={versionStatus}
          guid={supplemental?.guid}
          parentGuid={supplemental?.parent?.guid}
        />
        <div className='Version-content'>
          <Page className='Version-details'>
            <div className='Version-faux-table-header'>
              <AnalyticDiffSelector
                busy={leftTranslationStatus === Status.pending}
                title={
                  leftVersion ? (
                    `Version ${
                      filterByCurrentOrParentVersions(leftVersion).length -
                      filterByCurrentOrParentVersions(leftVersion).indexOf(leftVersion)
                    }`
                  ) : (
                    <Placeholder variant='text' width={50} />
                  )
                }
                subtitle={
                  leftVersion ? (
                    `${leftVersion?.created_by?.name} - ${formatCustomTimestamp(leftVersion?.creation, STANDARD)}`
                  ) : (
                    <Placeholder variant='text' width={100} />
                  )
                }
              />
              <AnalyticDiffSelector
                busy={rightTranslationStatus === Status.pending}
                title={
                  rightVersion ? (
                    `Version ${
                      filterByCurrentOrParentVersions(rightVersion).length -
                      filterByCurrentOrParentVersions(rightVersion).indexOf(rightVersion)
                    }`
                  ) : versionStatus !== Status.pending ? (
                    'Version ?'
                  ) : (
                    <Placeholder variant='text' width={50} />
                  )
                }
                subtitle={
                  rightVersion ? (
                    `${rightVersion?.created_by?.name} - ${formatCustomTimestamp(rightVersion?.creation, STANDARD)}`
                  ) : versionStatus !== Status.pending ? (
                    'make another version selection'
                  ) : (
                    <Placeholder variant='text' width={100} />
                  )
                }
              >
                <div className='Version-header-options'>
                  {isDeployable && !isPending && selectedLanguageId && (
                    <DeployIntegrationButton
                      guid={supplemental?.guid}
                      existingDeployment={orgDeployments?.[0]}
                      integration={deployedIntegration}
                      onDeploy={() => supplementalRefresh()}
                      showIfDisabled
                    >
                      {({ disabled, handleClickDeploy }) => (
                        <VersionDeployButton disabled={disabled} onClick={handleClickDeploy}>
                          Deploy
                        </VersionDeployButton>
                      )}
                    </DeployIntegrationButton>
                  )}
                </div>
              </AnalyticDiffSelector>
            </div>
            <ReactDiffViewer
              oldValue={leftContent}
              newValue={rightContent}
              splitView={true}
              showDiffOnly={false}
              useDarkTheme
              styles={{
                variables: {
                  dark: {
                    diffViewerTitleBackground: palette.background.paper,
                    diffViewerTitleColor: palette.greyish.main,
                    gutterBackground: palette.surface.auxiliaryTab,
                    diffViewerBackground: palette.surface.auxiliaryTab,
                    emptyLineBackground: palette.background.default,
                    codeFoldBackground: palette.background.paper,
                    codeFoldGutterBackground: palette.background.paper,
                    codeFoldContentColor: palette.primary.main
                    // addedGutterBackground: palette.lime.main,
                    // addedBackground: palette.lime.main,
                    // wordAddedBackground: palette.lime.light,
                    // addedColor: palette.getContrastText(palette.lime.dark)
                  }
                }
                /** various styles can be overridden here as well.
                 * But we are limited in what we can target and what
                 * selectors are available. See Analytic.style VersionDiff
                 * to see how we can target with wildcards */
              }}
            />
          </Page>
          <StyledToolbar className='Language-diff-toolbar'>
            <div className='Version-header-options'>
              {!isNative && selectedLanguageId && (
                <>
                  <StyledAutocomplete
                    name='LanguageSelection'
                    onChange={handleLanguageChange}
                    options={languageOptions}
                    value={getDisplayValue(languageOptions, selectedLanguage?.id.toString())}
                    disabled={
                      leftTranslationStatus === Status.pending ||
                      rightTranslationStatus === Status.pending ||
                      disableLanguageOption
                    }
                    disableUserAdditions
                    disableGroupSelect
                    disableClearable
                    isOptionEqualToValue={(option: Option, value: Option) => option.value === value.value}
                    PaperComponent={StyledAutocompletePaper}
                  />
                  <StyledIntegrationAutocomplete
                    className='IntegrationSelection'
                    onChange={handleIntegrationChange}
                    name='IntegrationSelection'
                    value={selectedIntegrationOptionValue}
                    disabled={disableIntegrationOption}
                    disableGroupSelect
                    disableClearable
                    disableUserAdditions
                    options={integrationOptions}
                  />
                </>
              )}
            </div>
          </StyledToolbar>
        </div>
      </VersionDiff>
    </IDETranslationStateProvider>
  );
}
