import React from 'react';

import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import { z } from 'zod';

import { FieldsLayout } from 'snap-ui/Layout';
import Typography from 'snap-ui/Typography';

import { AgentInterface } from 'aso/useAgents';

import { standardFormikBaseProps } from 'module/Form';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { Status } from 'storage';

import { BulkLaunchBASFormValues } from 'types/bas';

import { BASCampaign, BASCampaignFeed, OSName } from '../BAS.type';
import AgentField from '../Launcher/AgentField';
import Disclaimer from '../Launcher/Disclaimer';
import LauncherModal from '../Launcher/Modal';

type CampaignRerunDialogProps = {
  agentInterface: AgentInterface;
  onRerun(payload: BulkLaunchBASFormValues): Promise<BASCampaign>;
  onSuccess(newCampaign: BASCampaign): void;
  onClose(): void;
  open: boolean;
  campaign: BASCampaign | BASCampaignFeed;
};

export default function CampaignRerunDialog({
  agentInterface,
  open,
  onRerun,
  onSuccess,
  onClose,
  campaign
}: CampaignRerunDialogProps): JSX.Element {
  const { filteredAgents: agents, status: agentStatus } = agentInterface;
  const initialValues = (campaign.agents || [])
    .map(a => pick(a, ['os_name', 'guid']))
    .reduce(
      (initialValues, agent) => {
        return { ...initialValues, agents: { ...initialValues.agents, [agent.os_name]: agent.guid } };
      },
      {
        name: campaign.name,
        agents: {}
      }
    );

  console.log('initialValues: ', initialValues);

  const platforms = Object.keys(initialValues.agents);

  return (
    <LauncherModal
      {...standardFormikBaseProps}
      initialValues={initialValues}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitDisabled={isEmpty(agents) || agentStatus === Status.pending}
      subtitle={campaign.name}
      zodSchema={z.object({
        agents: z.record(z.nativeEnum(OSName), z.string())
      })}
    >
      <FieldsLayout>
        <TextFieldFormik label='Attack Simulation Name' name='name' />
        {platforms.map(platform => (
          <React.Fragment key={platform}>
            <Typography variant='h3'>{startCase(platform)}</Typography>
            <AgentField
              agentInterface={agentInterface}
              target_platforms={[platform] as OSName[]}
              name={`agents[${platform}]`}
            />
          </React.Fragment>
        ))}
        <Disclaimer />
      </FieldsLayout>
    </LauncherModal>
  );

  function handleSubmit(values: BulkLaunchBASFormValues) {
    return onRerun(values).then(onSuccess);
  }
}
