import { snapattack } from 'apis';

import { Guid } from 'types/common';
import { ComputedUrlResponse } from 'types/userSetting';

import {
  CreateIntegrationRequest,
  CreateJobSchedulePayload,
  Integration,
  IntegrationCount,
  JobSchedule,
  SchemaCatalog,
  SchemaSupported
} from './Integration.type';

const prefix = '/integrations';

export function postCloneIntegration(guid: Guid, payload: CreateIntegrationRequest): Promise<void> {
  return snapattack.post(`${prefix}/${guid}/clone/`, payload).then(r => r.data);
}

export function getIntegrations(): Promise<Integration[]> {
  return snapattack.get(`${prefix}/`).then(r => {
    return r.data.sort((a, b) => a.name.localeCompare(b.name));
  });
}

export function getIntegrationDeployments(guid: Guid): Promise<IntegrationCount> {
  return snapattack.get(`${prefix}/${guid}/deployments/`).then(r => r.data);
}

export function postIntegration(payload: CreateIntegrationRequest): Promise<void> {
  return snapattack.post(`${prefix}/`, payload).then(r => r.data);
}

export function postIntegrationUrl(guid: Guid, code: string): Promise<ComputedUrlResponse> {
  return snapattack.post(`${prefix}/${guid}/url/`, { search: code }).then(r => r.data);
}

export function postIntegrationDeployment(guid: Guid): Promise<void> {
  return snapattack.post(`${prefix}/${guid}/deploy/`).then(r => r.data);
}

export function putIntegration(guid: Guid, payload: CreateIntegrationRequest): Promise<void> {
  return snapattack.put(`${prefix}/${guid}/`, payload).then(r => r.data);
}

export function deleteIntegration(guid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/${guid}/`).then(r => r.data);
}

export function getSchemaSupported(): Promise<SchemaSupported> {
  return snapattack.get(`${prefix}/supported/`).then(r => r.data);
}

export function getSchemaCatalog(): Promise<SchemaCatalog> {
  return snapattack.get(`${prefix}/schema/v2/`).then(r => ({ catalog: r.data }));
}

export function getDeploymentSchemaCatalog(): Promise<SchemaCatalog> {
  return snapattack.get(`${prefix}/schema/deployment/`).then(r => r.data);
}

export function getDefaultTuningFields(): Promise<Record<Integration['type'], string[]>> {
  return snapattack.get(`${prefix}/default/tuning/`).then(r => r.data);
}

export function getIntegrationJobSchedules(integration_guid: Guid): Promise<JobSchedule[]> {
  return snapattack.get(`${prefix}/${integration_guid}/schedule/`).then(r => r.data);
}

export function getAllIntegrationJobSchedules(): Promise<JobSchedule[]> {
  return snapattack.get(`${prefix}/schedule/`).then(r => r.data);
}

export function deleteIntegrationJobSchedule(integration_guid: Guid, schedule_guid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/${integration_guid}/schedule/${schedule_guid}/`).then(r => r.data);
}

export function putIntegrationJobSchedule(
  integration_guid: Guid,
  schedule_guid: Guid,
  payload: CreateJobSchedulePayload
): Promise<void> {
  return snapattack.put(`${prefix}/${integration_guid}/schedule/${schedule_guid}/`, payload).then(r => r.data);
}

export function postIntegrationJobSchedule(integration_guid: Guid, payload: CreateJobSchedulePayload): Promise<void> {
  return snapattack.post(`${prefix}/${integration_guid}/schedule/`, payload).then(r => r.data);
}
