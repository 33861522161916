import React from 'react';

import isNil from 'lodash/isNil';

import { DataGridProps, GridColDef, useGridApiRef, useKeepGroupedColumnsHidden } from 'snap-ui/DataGrid';
import Typography from 'snap-ui/Typography';

import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { FunctionalPermission } from 'types/auth';

import { EventLogsTable, TableContainer } from '../AnalyticTuning.style';
import { Bucket } from '../AnalyticTuning.types';
import useTuningContext from '../AnalyticTuningProvider';
import {
  AnalyticTuningGridFooter,
  AnalyticTuningGridFooterProps,
  AnalyticTuningGridToolbar,
  CustomGridTreeDataGroupingCell
} from './AnalyticTuningGrid.widgets';

type AnalyticTuningGridProps = {
  fields: string[];
  hits: Record<string, string>[];
  onExclude: (field: string) => (value: Bucket) => void;
  totalHits: number;
};

const AnalyticTuningGrid = withFunctionalPermission(function AnalyticTuningGrid({
  fields,
  hits,
  totalHits,
  onExclude
}: AnalyticTuningGridProps) {
  const { fieldType } = useTuningContext();
  const apiRef = useGridApiRef();

  // hit log fields come back with their type prefix; strip this out
  const mappedHits = React.useMemo(
    () =>
      hits.map(hit => {
        return Object.entries(hit).reduce((accum, [key, value]) => {
          return { ...accum, [key.replace(`${fieldType}.`, '')]: value };
        }, {});
      }),
    [fieldType, hits]
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: fields.slice(0, 2)
      }
    }
  });

  const columns: GridColDef[] = fields.map(field => ({
    field,
    headerName: field,
    flex: 1,
    groupingValueGetter: ({ value }) => {
      if (isNil(value)) return '(empty)';
      return value;
    }
  }));

  const groupingColDef: DataGridProps['groupingColDef'] = params => {
    return {
      headerName: params.fields[0],
      flex: 1,
      minWidth: 300,
      renderCell: renderCellParams => <CustomGridTreeDataGroupingCell {...renderCellParams} />,
      onExclude
    };
  };

  return (
    <TableContainer className='span-columns'>
      <Typography variant='h3'>Tuning Grid</Typography>
      <EventLogsTable
        apiRef={apiRef}
        density='compact'
        columns={columns}
        rows={mappedHits}
        rowGroupingColumnMode='multiple'
        getRowId={() => Math.ceil(Math.random() * 100_000)}
        slots={{
          toolbar: AnalyticTuningGridToolbar,
          footer: AnalyticTuningGridFooter
        }}
        slotProps={{
          toolbar: { fields },
          footer: { totalHits } as AnalyticTuningGridFooterProps
        }}
        initialState={initialState}
        groupingColDef={groupingColDef}
      />
    </TableContainer>
  );
},
FunctionalPermission.Tuning);

export default AnalyticTuningGrid;
