import React from 'react';

import classnames from 'classnames';

import { BarChart, PieChart } from 'snap-ui/Charts';
import { BarChartProps } from 'snap-ui/Charts/BarChart';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { useTheme } from 'snap-ui/util';

import {
  BarChartContainer,
  BarChartPanel,
  BarChartPlacholders,
  BarChartToolbar,
  PlainStatContainer,
  StatPanelContainer,
  StatPanelPlaceholderContainer
} from './style';

type PanelProps = {
  className?: string;
  disableCard?: boolean;
  isPending?: boolean;
  title: string;
};

export function StatPanel({
  className,
  disableCard,
  dimensions,
  explanation,
  isPending,
  title,
  value
}: PanelProps & {
  /** value in percent (0 - 100) */
  value: number;
  dimensions?: { innerRadius: number; outerRadius: number };
  explanation: string;
}): JSX.Element {
  const { palette } = useTheme();
  const mainColor = value > 65 ? palette.lime : value > 33 ? palette.yellow : palette.red;
  const colors = [mainColor, palette.secondary];
  const height = dimensions ? dimensions.outerRadius * 2 : 150;

  const el = disableCard ? PlainStatContainer : StatPanelContainer;

  return React.createElement(
    el,
    { className },
    <>
      <Typography variant='h3'>
        <>
          {title}
          {explanation && (
            <Tooltip arrow placement='top' title={explanation} wrap>
              <Icon.Info />
            </Tooltip>
          )}
        </>
      </Typography>

      {isPending ? (
        <StatPanelPlaceholderContainer>
          <Placeholder variant='circular' height={height} width={height} />
        </StatPanelPlaceholderContainer>
      ) : (
        <div className='chart'>
          <label className='value'>{value}%</label>
          <PieChart
            colors={colors}
            height={height}
            outerRadius={dimensions?.outerRadius ?? 75}
            innerRadius={dimensions?.innerRadius ?? 60}
            data={[
              { name: 'Value', value },
              { name: 'Not Value', value: 100 - value }
            ]}
          />
        </div>
      )}
    </>
  );
}

export function ChartPanel({
  className,
  isPending,
  title,
  data,
  onBarClick
}: PanelProps & {
  data: BarChartProps['data'];
  onBarClick?: (data: BarChartProps['data'][number], index: number) => void;
}): JSX.Element {
  return (
    <BarChartPanel className={classnames('ChartPanel', className)}>
      <BarChartToolbar>
        <Typography variant='h3'>{title}</Typography>
      </BarChartToolbar>
      {isPending ? (
        <BarChartPlacholders>
          <Placeholder variant='rectangular' height={250} />
          <Placeholder variant='rectangular' height={125} />
          <Placeholder variant='rectangular' height={200} />
          <Placeholder variant='rectangular' height={50} />
          <Placeholder variant='rectangular' height={100} />
        </BarChartPlacholders>
      ) : (
        <BarChartContainer>
          <BarChart height={200} data={data} onBarClick={onBarClick} useTooltip />
        </BarChartContainer>
      )}
    </BarChartPanel>
  );
}
